/*.App {
  margin: 0.5rem;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(79deg, #7439db, #C66FBC 48%, #F7944D);
}*/

/* .App {
  background-image: linear-gradient(79deg, #7439db, #c66fbc 48%, #f7944d);
  background-size: cover;
  background-repeat: no-repeat;
} */

.App {
  background-color: #f5f5f5;
}

body {
  /* background-color: #f5f5f5; */
  margin: 0;
  display: block;
  text-align: center;
}

.auth-form-container,
.login-form,
.register-form {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .auth-form-container {
    padding: 5rem;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0.5rem;
  }
}

label {
  text-align: left;
  padding: 0.25rem 0;
}

input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
}

/* CSS for changing the login or register window and forget password window: */
button {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #7439db;
}

.link-btn {
  background: none;
  color: white;
  text-decoration: underline;
}

.data_grid_header_div .MuiDataGrid-columnHeaders {
  background-color: #5064d4;
  /* Change to your desired color */
  color: black;
  /* Optional: change text color to make it more readable */
}
