/* Calendar Header */
.rbc-header {
  background: #476f95;
  color: whitesmoke;
  /* padding: 6px; */
  font-size: 15px;
  font-weight: bold;
}

/* For the event  */
.rbc-event {
  padding: 0 !important;
  font-size: 18px;
  position: relative;
}

/* For the event label to display */
.rbc-event-label {
  display: none !important;
  display: flex;
  justify-content: flex-start;
}

.rbc-event-container {
  width: 100%;
}

.rbc-time-slot {
  background-color: #eaf3fa;
  color: #74a4c3;
  /* border-color: aqua; */
  height: 50px;
}

.rbc-row,
.rbc-row-resource {
  height: 30px;
}

.rbc-row,
.rbc-time-header-cell {
  height: 40px;
}

.rbc-event-content {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: left;
}

.rbc-row-segment {
  flex-basis: 100% !important;
}

.rbc-row-content {
  height: 20px; /* Match the height of .rbc-row-bg */
}

/* ////////////////////////////////////////////////////////////////// */

.rbc-toolbar {
  background-color: #476f95; /* Darker shade for better contrast */
  color: #ffffff; /* White text for clarity */
  font-size: 16px; /* Slightly larger font for better readability */
  font-weight: bold;
  padding: 10px 15px; /* Add padding for a cleaner layout */
  border-radius: 5px; /* Rounded corners for a modern look */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.rbc-btn-group {
  font-size: 14px;
  font-weight: bold;
}

.rbc-btn-group button {
  background-color: #ffffff; /* White background for buttons */
  color: #476f95; /* Match the toolbar for a cohesive look */
  border: 1px solid #476f95; /* Outline for distinction */
  padding: 5px 10px; /* Comfortable padding */
  margin: 0 5px; /* Space between buttons */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s, color 0.3s; /* Smooth hover transition */
}

.rbc-btn-group button:hover {
  background-color: red; /* Invert colors on hover */
  color: #ffffff; /* White text on hover */
}
